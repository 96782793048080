


































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { API } from '@/services/api';
import { IUser } from '@/types/user';
import { emailRules, passwordEdit, passwordCreate, name } from '@/utils/validation';
import UserAvatar from '@/components/UserAvatar.vue';
/**
 * https://vuetifyjs.com/en/components/data-tables/#crud-actions
 */

@Component({ components: { UserAvatar } })
export default class TeachersPage extends Vue {
  protected dialog = false;
  protected dialogDelete = false;
  protected search = '';
  headers = [
    { text: 'ID', align: 'start', sortable: true, value: 'id' },
    { text: 'Фото', sortable: false, value: 'avatar' },
    { text: 'Email', value: 'email' },
    { text: this.$t.title.lastName, value: 'lastName', sortable: true },
    { text: this.$t.title.firsName, value: 'firstName' },
    { text: this.$t.title.role, value: 'role.description', sortable: true },
    { text: this.$t.title.activated, value: 'activated', sortable: true },
    { text: this.$t.title.updatedAt, value: 'updatedAt', sort: (a: any, b: any) => this.sortDate(a, b) },
    { text: this.$t.title.createdAt, value: 'createdAt', sort: (a: any, b: any) => this.sortDate(a, b) },
    { text: this.$t.title.actions, value: 'actions', sortable: false },
  ];
  users: Array<IUser | any> = [];
  editedIndex = -1;
  editedItem = {
    id: 0,
    email: '',
    lastName: '',
    firstName: '',
    activated: true,
    password: '',
  };
  defaultItem = {
    id: 0,
    email: '',
    lastName: '',
    firstName: '',
    activated: true,
    password: '',
  };

  rules = {
    isValid: true,
    showPassword: false,
    email: emailRules,
    passwordEdit,
    passwordCreate,
    name,
  };

  get formTitle() {
    return this.editedIndex === -1
      ? this.$t.action.user.create
      : this.$t.action.user.update;
  }

  get buttonSaveCreate() {
    return this.editedIndex === -1
      ? { title: this.$t.button.create, color: this.$accent }
      : { title: this.$t.button.save, color: 'blue' };
  }

  @Watch('dialog')
  dialogWatch(val: any) {
    return val || this.close();
  }

  @Watch('dialogDelete')
  dialogDeleteWatch(val: any) {
    return val || this.closeDelete();
  }

  created() {
    this.getUsers();
  }

  async getUsers() {
    try {
      const users = (await API.users.fetchAll()).data;
      if (users) {
        this.users = users.map((user) => ({
          ...user,
          formattingCreatedAt: new Date(user.createdAt),
          formattingUpdatedAt: new Date(user.updatedAt),
        }));
      }
    } catch (error: any) {
      this.$notify({
        type: 'error',
        content: error,
      });
    }
  }

  editItem(item: any) {
    this.editedIndex = this.users.indexOf(item);
    this.editedItem = { ...item };
    this.dialog = true;
  }

  deleteItemConfirm() {
    this.users.splice(this.editedIndex, 1);
    this.closeDelete();
  }

  close() {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedItem = { ...this.defaultItem };
      this.editedIndex = -1;
    });
  }

  closeDelete() {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.editedItem = { ...this.defaultItem };
      this.editedIndex = -1;
    });
  }

  async save() {
    if (this.editedIndex > -1) {
      this.updateUser();
    } else {
      this.createUser();
    }
  }

  async createUser() {
    try {
      const user = (await API.users.create(this.editedItem)).data;
      if (user) this.users.push(user);
      this.close();
      this.$notify({
        type: 'success',
        content: this.$t.notify.user.created,
      });
    } catch (error: any) {
      this.$notify({
        type: 'error',
        content: error,
      });
    }
  }

  async updateUser() {
    try {
      const user = (await API.users.update(this.editedItem)).data;
      if (user) Object.assign(this.users[this.editedIndex], user);
      this.close();
      this.$notify({
        type: 'success',
        content: this.$t.notify.user.updated,
      });
    } catch (error: any) {
      this.$notify({
        type: 'error',
        content: error,
      });
    }
  }

  sortDate(a: string, b: string) {
    const aDate = new Date(a);
    const bDate = new Date(b);
    if (aDate > bDate) return 1;
    if (aDate < bDate) return -1;
    return 0;
  }
}
